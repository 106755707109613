
import { matchRoutes } from 'react-router-dom';

import { home, logout as homeLogout } from 'service/route';

const routes = [
    { path: homeLogout.paths, element: () => import('./entry_logout') },
    { path: home.paths, element: () => import('./entry') }
];

let routeMatches = matchRoutes(routes, window.location);

if (routeMatches == null) {
    routeMatches = [];
}

for (const routeMatch of routeMatches) {

    const factory = routeMatch.route.element;

    if (factory instanceof Function) {
        factory();

        // Render first matched route
        break;
    }
}