
export const AccountTab = {
    Information: 'information',
    Balance: 'balance',
    BalanceHistory: 'balance-history',
    Orders: 'orders',
    Positions: 'positions',
    Transactions: 'transactions',
    Restrictions: 'restrictions',
    TradeTicket: 'trade-ticket',
    OptionsTradeTicket: 'options-trade-ticket',
};